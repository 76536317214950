<template>
  <div class="btn btn-light btn-sm font-weight-bolder btn-upper btn-text" v-on:click="$emit('button-clicked')">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AppSmallLightButton",
};
</script>

<style scoped></style>
