<template>
  <b-modal
    id="employee-monthly-attendance-modal"
    :title="title"
    size="xl"
    footer-bg-variant="light"
    ok-only
    lazy
    @show="$emit('openModal')"
  >
    <div class="d-flex flex-column flex-lg-row p-3 mr-5">
      <CommonEmployeeSummary :item="employee"></CommonEmployeeSummary>
      <!--      <EmployeeAttendanceManageDate :date="date"></EmployeeAttendanceManageDate>-->
    </div>

    <IndividualEmployeeAttendanceMonthlyTable
      :items="items"
      :is-busy="isBusy"
      :pagination="pagination"
      @showClock="$emit('showClock', $event)"
      @showManageModal="$emit('showManageModal', $event)"
      @showUpdateModal="$emit('showUpdateModal', $event)"
      @changePage="$emit('changePage', $event)"
    ></IndividualEmployeeAttendanceMonthlyTable>
  </b-modal>
</template>

<script>
import IndividualEmployeeAttendanceMonthlyTable from "../table/IndividualEmployeeAttendanceMonthlyTable";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import dayjs from "dayjs";

export default {
  name: "IndividualEmployeeMonthlyAttendanceModal",
  components: {
    CommonEmployeeSummary,
    IndividualEmployeeAttendanceMonthlyTable,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    pagination: {
      type: Object,
      required: true,
    },
    date: {
      type: Date,
      required: true,
      default: new Date(dayjs().format()),
    },
    employee: {
      type: Object,
      required: true,
    },
    records: {
      type: Array,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Employee Monthly Attendance",
    },
  },
};
</script>

<style scoped></style>
