<template>
  <div>
    <b-table
      :busy="isBusy"
      :fields="fields"
      :items="items"
      :responsive="true"
      table-class="table-head-custom table-vertical-center table-head-bg mt-3"
      hover
      show-empty
      outlined
    >
      <!-- begin:header template    -->
      <template v-slot:head(date)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end:header template    -->

      <!-- begin: cell templates      -->

      <template v-slot:cell(date)="data">
        <div class="d-flex align-items-center">
          <div class="ml-4">
            <AppSmallLightButton>
              {{ transformDate(data.item.date) }}
            </AppSmallLightButton>
          </div>
        </div>
      </template>
      <!-- end:: date      -->

      <!-- begin:: date      -->
      <!--      <template v-slot:cell(day)="data">-->
      <!--        <div class="d-flex align-items-center">-->
      <!--          <div class="ml-4">-->
      <!--            <span class="ml-3 font-weight-bold">{{-->
      <!--              getDay(data.item.date)-->
      <!--            }}</span>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </template>-->
      <!-- end:: date      -->

      <template v-slot:cell(time_in)="data">
        <TheTimeEntryArrow
          v-if="data.item.attendance"
          :time="data.item.attendance.time_in"
          :is-late="data.item.attendance.late"
          :display-late="true"
          type="in"
        ></TheTimeEntryArrow>
      </template>

      <template v-slot:cell(time_out)="data">
        <TheTimeEntryArrow
          v-if="data.item.attendance"
          :time="data.item.attendance.time_out"
          type="out"
        ></TheTimeEntryArrow>
      </template>

      <template v-slot:cell(status)="data">
        <span>{{ data.item.status.name }}</span>
      </template>

      <template v-slot:cell(temperature)="data">
        <span
          class="font-weight-bolder"
          v-bind:class="{
            'text-success': data.item.attendance.temperature.is_safe,
            'text-danger': !data.item.attendance.temperature.is_safe,
          }"
          v-if="data.item.attendance && data.item.attendance.temperature"
        >
          {{ data.item.attendance.temperature.temp + "\xB0C" }}
        </span>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-right">
          <!-- begin: Device Users Modal Button         -->
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="$emit('showClock', data)"
          >
            <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
              <inline-svg src="/media/svg/icons/Home/Alarm-clock.svg" />
            </span>
          </button>

          <b-button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="$emit('showManageModal', data)"
          >
            <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Group.svg" />
            </span>
          </b-button>

          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="$emit('showUpdateModal', data)"
          >
            <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
              <inline-svg src="/media/svg/icons/Home/Clock.svg" />
            </span>
          </button>
        </div>
      </template>
      <!-- end: cell templates      -->

      <template #table-busy>
        <AppLoader></AppLoader>
      </template>
    </b-table>
    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        size="lg"
        class="pt-5"
        @change="$emit('changePage', $event)"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="employee-monthly-attendance-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import i18n from "@/core/plugins/vue-i18n";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import dayjs from "dayjs";
import TheTimeEntryArrow from "@/modules/company/components/shared/TheTimeEntryArrow";
import AppSmallLightButton from "@/modules/core/components/button/AppSmallLightButton";

export default {
  name: "IndividualEmployeeAttendanceMonthlyTable",
  components: {
    AppSmallLightButton,
    TheTimeEntryArrow,
    AppLoader,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: Array,
    },
    pagination: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        { key: "date", label: i18n.t("EDUATTENDANCE.GENERAL.DATE") },
        // { key: "day", label: i18n.t("EDUATTENDANCE.GENERAL.DAY") },
        { key: "time_in", label: i18n.t("ATTENDANCE.IN") },
        { key: "time_out", label: i18n.t("ATTENDANCE.OUT") },
        { key: "temperature", label: i18n.t("ATTENDANCE.TEMPERATURE") },
        { key: "status", label: "Status" },
        { key: "actions", label: i18n.t("ATTENDANCE.ACTIONS") },
      ],
      attendanceId: null,
    };
  },
  mounted() {},

  methods: {
    transformDate(date) {
      const t = new Date(date);
      // https://day.js.org/docs/en/display/format
      return dayjs(t).format("DD/MM");
    },

    getDay(date) {
      const t = new Date(date);
      // https://day.js.org/docs/en/display/format
      return dayjs(t).format("dddd");
    },

    refresh() {},
  },
};
</script>

<style scoped></style>
