import { render, staticRenderFns } from "./AppSmallLightButton.vue?vue&type=template&id=1e67bfeb&scoped=true&"
import script from "./AppSmallLightButton.vue?vue&type=script&lang=js&"
export * from "./AppSmallLightButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e67bfeb",
  null
  
)

export default component.exports